@import '../src/scss/themes/theme-dark.scss';
// @import '../src/themes/scss/themes/theme-light.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
}

.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title-container {
	height: 50px;
}

.title-styles {
	font-family: 'Raleway', sans-serif;
	font-size: 250%;
}

.header-icon {
	height: 150px;
	width: 150px;
}

header {
	position: relative;
	height: 520px;
	min-height: 450px;
	width: 100%;
	background-size: cover;
	-webkit-background-size: cover;
	text-align: center;
	overflow: hidden;
	background-color: #e9d5a1;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding-top: 150px;
	padding-bottom: 0;
}

.language-icon {
	font-size: 50px;
	cursor: pointer;
}

.project-date {
	font-size: 16px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	padding: 8px 15px 2px 15px;
	position: relative;
	z-index: -500;
	border-top: 5px solid #696969;
	border-radius: 0 0 5px 5px;
	background-color: #696969;
	color: white;
}

.skills-tile {
	background-color: transparentize(#F8F5F4, 0.95);
	padding: 10px 10px 5px 10px;
	width: 100px;
	margin: 5px 0 5px 0;
	border-radius: 8px;
}

.language {
	font-size: 25px;
	background-color: #e9d5a1;
	padding-bottom: 10px;
	padding-top: 80px;
}

header h1 {
	font-size: 400%;
	text-align: center;
	font-weight: 600 !important;
	color: #353239;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	margin: 0 auto 18px auto;
	width: 100%;
}

.slider-image {
	border: 5px solid #D7CAAA;
}

.slider-tab {
	background-color: #D7CAAA;
	height: 25px;
}

.aws-btn {

	--slider-height-percentage: 65%;
}

.slider-iconfiy {
	margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
	cursor: none !important;
	display: none;
}

#about {
	background: #fff;
	overflow: hidden;
}

#about h1 {
	padding-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.foto img {
	display: block;
	width: 100%;
	height: 100%;
}

.foto>div:nth-of-type(1) {
	background: #ffffff;
	display: inline-block;
	margin: 0 auto 5% auto;
	padding: 10px 10px 5px;
	border-radius: 15px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.foto div:after {
	color: #333;
	font-size: 25px;
	content: attr(title);
	position: relative;
	top: 15px;
}

.foto>div:hover:nth-of-type(1) {
	-webkit-transform: scale(1.01);
	-moz-transform: scale(1.01);
	transform: scale(1.01);
	z-index: 10;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

.project-title-settings {
	margin-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

a:hover {
	color: whitesmoke !important;
}

.polaroid img {
	display: block;
	max-width: 200px;
}

.polaroid span {
	background: #ffffff;
	display: inline-block;
	margin: 55px 75px 30px;
	padding: 15px 15px 30px;
	border-radius: 15px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.link-href {
	color: black;
}

.wave {
	font-size: 160%;
}

.font-trebuchet {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#preview {
	width: 500px;
	background-color: #ebeaf5;
	padding: 15px;
	position: relative;
	margin-bottom: 15%;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

#resume {
	background: #efe1bd;
}

.freelance-links a {
	color: white !important;
	text-decoration: none;
}

.freelance-links a:link {
	text-decoration: underline;
}

.freelance-links a:visited {
	text-decoration: none;
}

.freelance-links a:hover {
	color: lawngreen !important;
	text-decoration: none;
}

.freelance-links a:active {
	text-decoration: none;
}

.experience-icon {
	font-size: 300%;
	margin-top: 25%;
	text-align: center;
}

.main-badge {
	font-size: 13px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #AE944F !important;
	color: white;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.experience-badge {
	font-size: 11px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #f9f5e9 !important;
	color: black;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.vertical-timeline-element-date {
	padding: 0 !important;
	margin: 0 !important;
}

@media only screen and (max-width: 1170px) {
	.experience-icon {
		font-size: 170%;
		margin-top: 27%;
		text-align: center;
	}
}

.modal-inside .modal-content {
	background: white;
}

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}

.bars .skills {
	margin-top: 36px;
	list-style: none;
}

.bars li {
	position: relative;
	margin-bottom: 60px;
	background: #ccc;
	height: 42px;
	border-radius: 3px;
}

.bars li em {
	font: 15px 'opensans-bold', sans-serif;
	color: #313131;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: normal;
	position: relative;
	top: -36px;
}

.bar-expand {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding-right: 24px;
	background: #313131;
	display: inline-block;
	height: 42px;
	line-height: 42px;
	border-radius: 3px 0 0 3px;
}

.modal-close {
	text-align: right;
	padding: 10px 15px 10px 15px;
	cursor: pointer;
}

.close-icon {
	color: black;
	font-weight: lighter !important;
}

.modal-description {
	text-align: justify;
	padding: 5px 5px 0 5px;
	margin-bottom: 20px;
	font-size: 16px;
}

.awssld__next {
	outline: none !important;
}

.awssld__prev {
	outline: none !important;
}

.loader-bar-color {
	color: black !important;
}

#portfolio {
	background: #efe1bd;
	padding-bottom: 5%;
}

.portfolio-item {
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
	-webkit-transition: all ease 0.5s;
	-moz-transition: all ease 0.5s;
	transition: all ease 0.5s;
	opacity: 0;
	background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
	opacity: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
	font-size: 1.5rem;
}

@media (min-width: 576px) {
	.portfolio .closeButtonResponsive {
		display: block;
	}

	.portfolio .portfolio-item {
		margin-bottom: 30px;
	}
}

#skills {
	background: #1F1F1F;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 10%;
}

#contact {
	background: #e2e2e2;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 10%;
}

.contact-form input {
	color: black !important;
	font-size: 14px;
}

.contact-form textarea {
	color: black !important;
	font-size: 14px;
}

.contact-form label {
	font-size: 14px;
}

input[type=button],
input[type=submit],
input[type=reset] {
	border: black 1px solid;
	border-radius: 5px;
	color: black;
	padding: 8px 32px;
	text-decoration: none;
	margin: 4px 2px;
	cursor: pointer;
}

.section-title {
	padding-top: 5%;
	padding-bottom: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #fff;
	text-align: center;
}

.skill-icon {
	font-size: 180%;
	color: white;
	text-align: center;
	position: relative;
}

.card-description {
	color: white;
	font-size: 12pt;
	padding: 8px;
}

.list-inline:hover {}

footer {
	background: #1F1F1F;
	min-height: 100px;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	color: white;
	position: relative;
	text-align: center;
}

footer a,
footer a:visited {
	color: #fff;
}

footer a:hover,
footer a:focus {
	color: #fff;
}

.social-links {
	margin-top: 50px;
	font-size: 22px;
}

.resConfirmed {
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	background: #272727;
	font-family: "Montserrat", sans-serif;
}

// .navMenu {
//   position: absolute;
//   top: 10%;
//   left: 50%;
//   -webkit-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
// }

// .navMenu a {
//   color: #f6f4e6;
//   text-decoration: none;
//   font-size: 1.2em;
//   text-transform: uppercase;
//   font-weight: 500;
//   display: inline-block;
//   width: 100px;
//   -webkit-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
// }

// .navMenu a:hover {
//   color: #fddb3a;
// }

// .navMenu .dot {
//   width: 6px;
//   height: 6px;
//   background: #fddb3a;
//   border-radius: 50%;
//   opacity: 0;
//   -webkit-transform: translateX(30px);
//   transform: translateX(30px);
//   -webkit-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
// }

// .navMenu a:nth-child(1):hover ~ .dot {
//   -webkit-transform: translateX(30px);
//   transform: translateX(30px);
//   -webkit-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
//   opacity: 1;
// }

// .navMenu a:nth-child(2):hover ~ .dot {
//   -webkit-transform: translateX(110px);
//   transform: translateX(110px);
//   -webkit-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
//   opacity: 1;
// }

// .navMenu a:nth-child(3):hover ~ .dot {
//   -webkit-transform: translateX(200px);
//   transform: translateX(200px);
//   -webkit-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
//   opacity: 1;
// }

// .navMenu a:nth-child(4):hover ~ .dot {
//   -webkit-transform: translateX(285px);
//   transform: translateX(285px);
//   -webkit-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
//   opacity: 1;
// }

// .navMenu a:nth-child(5):hover ~ .dot {
// 	-webkit-transform: translateX(350px);
// 	transform: translateX(350px);
// 	-webkit-transition: all 0.2s ease-in-out;
// 	transition: all 0.2s ease-in-out;
// 	opacity: 1;
// }


nav {
	position: absolute;
	top: 10%;
	right: 0;
	left: 0;
	width: 319px;
	display: table;
	margin: 0 auto;
	transform: translateY(-50%);
}

nav a {
	position: relative;
	width: 33.333%;
	display: table-cell;
	text-align: center;
	color: #949494;
	text-decoration: none !important;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-weight: bold;
	font-size: 18px;
	padding: 10px 20px;
	transition: 0.2s ease color;
}

nav a:before,
nav a:after {
	content: "";
	position: absolute;
	border-radius: 50%;
	transform: scale(0);
	transition: 0.2s ease transform;
}

nav a:before {
	top: 0;
	left: 10px;
	width: 6px;
	height: 6px;
}

nav a:after {
	top: 5px;
	left: 18px;
	width: 4px;
	height: 4px;
}

nav a:nth-child(1):before {
	background-color: yellow;
}

nav a:nth-child(1):after {
	background-color: red;
}

nav a:nth-child(2):before {
	background-color: #00e2ff;
}

nav a:nth-child(2):after {
	background-color: #89ff00;
}

nav a:nth-child(3):before {
	background-color: purple;
}

nav a:nth-child(3):after {
	background-color: palevioletred;
}

nav a:nth-child(4):before {
	background-color: rgb(149, 150, 250);
}

nav a:nth-child(4):after {
	background-color: rgb(112, 195, 216);
}

nav a:nth-child(5):before {
	background-color: rgb(217, 236, 42);
}

nav a:nth-child(5):after {
	background-color: rgb(216, 112, 164);
}

#indicator {
	position: absolute;
	left: 5%;
	bottom: 0;
	width: 30px;
	height: 3px;
	background-color: #fff;
	border-radius: 5px;
	transition: 0.2s ease left;
}

nav a:hover {
	color: #fff;
}

nav a:hover:before,
nav a:hover:after {
	transform: scale(1);
}

nav a:nth-child(1):hover~#indicator {
	background: linear-gradient(130deg, yellow, red);
}

nav a:nth-child(2):hover~#indicator {
	left: 40%;
	background: linear-gradient(130deg, #00e2ff, #89ff00);
}

nav a:nth-child(3):hover~#indicator {
	left: 85%;
	background: linear-gradient(130deg, purple, palevioletred);
}

nav a:nth-child(4):hover~#indicator {
	left: 120%;
	background: linear-gradient(130deg, rgb(0, 128, 43), rgb(216, 180, 112));
}

nav a:nth-child(5):hover~#indicator {
	left: 175%;
	background: linear-gradient(130deg, rgb(0, 70, 128), rgb(112, 216, 193));
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	#site-nav {
		display: none;
	}

	.mobile-nav {
		display: block;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
	#site-nav {
		margin-top: 5%;
		margin-right: 50%;
	}

	.mobile-nav {
		display: none;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	#site-nav {
		margin-right: 50%;
	}

	.mobile-nav {
		display: none;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	#site-nav {
		display: block;
	}

	.mobile-nav {
		display: none;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	#site-nav {
		display: block;
	}

	.mobile-nav {
		display: none;
	}
}